.content {
	position: relative;
	width: 100%;
	margin-bottom: 100px;
}

#banner {
	width: 100%;
}

.safeguardContent {
	width: 100%;
}

.safeguardContent li{
	width: 20%;
	display: inline-block;
}

.product	{
	width: 100%;
}

.max_img {
	width: 100%;
}

#why_content {
	width: 100%;
	position: absolute;
}

.why1 {
	width: 25%;
	position: absolute;
	/*top: 47px;*/
	left: 10%;
	margin-top: 3%;
}
.why2 {
	width: 20%;
	position: absolute;
	/*top: 450px;*/
	left: 10%;
	margin-top: 21%;
}
.why3 {
	width: 20%;
	position: absolute;
	/*top: 160px;*/
	right: 10%;
	margin-top: 7%;
}
.why4 {
	width: 25%;
	position: absolute;
	/*top: 700px;*/
	right: 15%;
	margin-top: 27%;
}
.title {
	margin-bottom: 5%;
	width: 22%;
}

.p_banner{
	display: inline-block;
}

.relative{
	position: relative;
}

.mark {
	position: absolute;
	width: 20%;
}
.spot {
	position: absolute;
	bottom: 22%;
	right: 10%;
	width: 5%;
}
.spot img {
	width: 20%;
	margin-right: 15%;
}
.hide {
	display: none;
}
.safe {
	width: 80%;
}
.aut_img {
	width: 80%;
}




.notice {
/*	padding-left: 734px;
    padding-right: 734px;*/
    /*text-align: center;*/
    width: 566px;
    margin: auto;
}
.notice_header {
	font-size: 21px;
}
.left {
	text-align: left;
}
.right {
	text-align: right;
}
.line_height {
	line-height: 34px;
}
.margin {
	margin-top: 30px;
}