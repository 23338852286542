#header {
	width: 100%;
	height: 110px;
	background: white;
	position: relative;
	box-shadow: 0px 4px 13px gray;
	z-index:1;
}
#logo {
	text-align: left;
	position: absolute;
	top: 24px;
	width: 100%;
}
#logo img {
	margin-left: 10%;
}
#menu {
	position: absolute;
	width: 100%;
	top: -16px;
	text-align: right;
}
#menu li {
	display: inline-block;
	height: 102px;
	border-width: 4px;
	border-style: solid;
	border-color: white white white white;
	line-height: 102px;
	margin-right: 5%;
}
#menu .active{
	border-color: #4f8de1 white white white;
}
.hand_point {
	cursor:pointer
}
.hide {
	display: none;
}