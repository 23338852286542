#footer {
	position: relative;
	height: 190px;
	width: 100%;
	background-image: linear-gradient(90deg, #4f8de1, #86b6f7);
}
#footer_right {
	position: absolute;
	right: 10%;
}
#footer_right img{
	margin-top: 35px;
	height: 120px;
}
#footer_left {
	color: white;
	position: absolute;
	left: 10%;
}
.line_block {
	margin-left: 10px;
	display: inline-block;
}
.font_small {
	margin-left: 96px;
	font-size: 10px;
	margin-bottom: 5px;
	text-align: left;
}
#footer_menu {
	margin-top: 35px;
	margin-bottom: 10px;
}
.hand_point {
	cursor:pointer
}
.hide {
	display: none;
}